import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Rating, TextField } from "@mui/material";

const TransitionsModal = ({
  onChangeRating,
  ratingValue,
  onChangeName,
  name,
  onChangeComment,
  comment,
  onChangeEmail,
  email,
  onClickSubmit,
  handleImageClick,
}) => {
  const images = [
    {
      src: "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/scott-soap-dispensers.jpg?w=170&h=170&fit=crop&auto=format",
      srcSet:
        "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/scott-soap-dispensers.jpg?w=170&h=170&fit=crop&auto=format&dpr=2 2x",
    },
    {
      src: "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/foaming-bottles-review-page.jpg?w=170&h=170&fit=crop&auto=format",
      srcSet:
        "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/foaming-bottles-review-page.jpg?w=170&h=170&fit=crop&auto=format&dpr=2 2x",
    },
    {
      src: "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/utility.jpg?w=170&h=170&fit=crop&auto=format",
      srcSet:
        "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/utility.jpg?w=170&h=170&fit=crop&auto=format&dpr=2 2x",
    },
    {
      src: "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/mist-bottles-review-page.jpg?w=170&h=170&fit=crop&auto=format",
      srcSet:
        "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/mist-bottles-review-page.jpg?w=170&h=170&fit=crop&auto=format&dpr=2 2x",
    },
    {
      src: "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/pour-spouts-review-page.jpg?w=170&h=170&fit=crop&auto=format",
      srcSet:
        "https://cdn11.bigcommerce.com/s-i17pk3h6/images/stencil/original/image-manager/pour-spouts-review-page.jpg?w=170&h=170&fit=crop&auto=format&dpr=2 2x",
    },
  ];
  return (
    <Box sx={styles.contanier}>
      <Box sx={styles.header}>
        <Typography id="transition-modal-title" variant="h5" sx={styles.title}>
          Reviews
        </Typography>
      </Box>

      <Typography id="transition-modal-description" sx={{ mt: 3 }} variant="h4">
        What type of Rail19 product did you purchase?
      </Typography>

      <Box>
        {images.map((item, index) => {
          return (
            <Button onClick={() => handleImageClick(item.src)} key={index}>
              <img
                src={item.src}
                srcSet={item.srcSet}
                loading="lazy"
                alt=""
                style={styles.img}
                key={index}
              />
            </Button>
          );
        })}
      </Box>

      <Typography
        id="transition-modal-description"
        sx={{ mt: 2, mb: 2 }}
        variant="h4"
      >
        Please rate your satisfaction
      </Typography>
      <Rating
        name="simple-controlled"
        value={ratingValue}
        onChange={onChangeRating}
        size="large"
      />
      <Typography
        id="transition-modal-description"
        sx={styles.attributeTitle}
        variant="h4"
      >
        Name*
      </Typography>
      <TextField fullWidth={true} onChange={onChangeName} value={name} />
      <Typography
        id="transition-modal-description"
        sx={styles.attributeTitle}
        variant="h4"
      >
        Addition Comments
      </Typography>
      <TextField
        fullWidth={true}
        multiline={true}
        rows={2}
        maxRows={2}
        onChange={onChangeComment}
        value={comment}
      />
      <Typography
        id="transition-modal-description"
        sx={styles.attributeTitle}
        variant="h4"
      >
        Your Email Address
      </Typography>
      <TextField fullWidth={true} onChange={onChangeEmail} value={email} />
      <Button
        variant="contained"
        sx={styles.submit}
        size="large"
        onClick={onClickSubmit}
      >
        Submit
      </Button>
    </Box>
  );
};
const styles = {
  submit: {
    backgroundColor: "black",
    borderRadius: 30,
    paddingHorizontal: 20,
    marginTop: 1,
    ":hover": {
      backgroundColor: "black",
    },
  },
  contanier: {
    margin: "auto",
    display: "contents",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 2,
    borderRadius: 3,
  },
  attributeTitle: {
    mt: 3,
    mb: 1,
  },
  img: { padding: 10, elevation: 7, height: 150, width: 150 },
  close: {
    justifyContent: "center",
    width: 20,
    height: 40,
    color: "black",
  },
  icon: { color: "black", marginLeft: 2 },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

export default TransitionsModal;
