import React from "react";
import { Reviews, PublishedReviews, SubmitScreen } from "./screens";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <PublishedReviews />,
  },
  {
    path: "reviews/",
    element: <Reviews />,
  },
  {
    path: "submit/",
    element: <SubmitScreen />,
  },
]);
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
