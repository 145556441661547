import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  Container,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { ReviewCard } from "../components";
import axios from "axios";

const PublishedReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [authenticate, setAuthenticate] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    axios
      .get("https://review.rail19.com:5000/api/reviews-admin")
      .then((res) => setReviews(res.data))

      .catch((err) => console.log(err));
  }, []);
  const handleClick = (id) => {
    axios
      .put("https://review.rail19.com:5000/api/reviews", {
        id: id,
        isPublished: true,
      })
      .then((res) => {
        if (res.data === "Review Updated") {
          const temp = reviews.filter((item) => !(item.id === id));

          alert("Review Published!");
          setReviews(temp);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = () => {
    if (user !== "admin" && password !== "admin") {
      setUser("");
      setPassword("");
      alert("Wrong User Name or Password");
    } else {
      setAuthenticate(true);
    }
  };
  return (
    <>
      <CssBaseline />
      <main>
        <div>
          <Container maxWidth="lg">
            {authenticate ? (
              reviews.length > 0 ? (
                <>
                  <Typography
                    variant="h4"
                    sx={{ marginTop: 5, marginBottom: 5 }}
                  >
                    Pending Reviews
                  </Typography>
                  {reviews?.map((item, index) => {
                    return (
                      <ReviewCard
                        review={item?.review}
                        index={index}
                        key={index}
                        name={item?.name}
                        comment={item?.comment}
                        date={item?.createdAt.slice(0, 10)}
                        src={item?.img}
                        withPublishButton={true}
                        onClick={() => handleClick(item.id)}
                      />
                    );
                  })}
                </>
              ) : (
                <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 5 }}>
                  No Reviews Pending!
                </Typography>
              )
            ) : (
              <div style={{ justifyContent: "center", display: "grid" }}>
                <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 5 }}>
                  Rail 19 Reviews Admin
                </Typography>

                <TextField
                  id="outlined-basic"
                  label="User"
                  variant="outlined"
                  sx={{ marginTop: 5, marginBottom: 5 }}
                  onChange={(e) => setUser(e.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  sx={{ marginBottom: 5 }}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  sx={{
                    backgroundColor: "black",
                    borderRadius: 30,
                    margin: 2,
                    ":hover": {
                      backgroundColor: "black",
                    },
                    color: "white",
                  }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </div>
            )}
          </Container>
        </div>
      </main>
    </>
  );
};

export default PublishedReviews;
