import React, { useState, useEffect } from "react";
import { CssBaseline, Container } from "@mui/material";
import { SubmitForm } from "../components";
import axios from "axios";

const SubmitScreen = () => {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(1);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [reviews, setReviews] = useState([]);
  const [img, setImg] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    if (!(name, value, comment, email)) return alert("Please fill all fields");
    if (!img) return alert("Please select the product you buy");
    const temp = [...reviews];

    axios
      .post("https://review.rail19.com:5000/api/reviews", {
        name,
        email,
        comment: comment,
        img: img,
        review: value,
      })
      .then((res) =>
        alert(
          "Thank you for your review.it's under examination once admin approved you will be able too see in reviews page"
        )
      )
      .catch((err) => console.log(err));

    setReviews(temp);
    setName("");
    setComment("");
    setEmail("");
    setValue(1);
    setImg("");
    setOpen(false);
  };
  const handleImageClick = (img) => {
    console.log(img);
    setImg(img);
  };
  useEffect(() => {
    axios
      .get("https://review.rail19.com:5000/api/reviews")
      .then((res) => {
        setReviews(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <SubmitForm
      open={true}
      //   handleClose={handleClose}
      onChangeRating={(event, newValue) => {
        setValue(newValue);
      }}
      ratingValue={value}
      onChangeName={(e) => setName(e.target.value)}
      name={name}
      onChangeComment={(e) => setComment(e.target.value)}
      comment={comment}
      onChangeEmail={(e) => setEmail(e.target.value)}
      email={email}
      onClickSubmit={handleSubmit}
      handleImageClick={(img) => handleImageClick(img)}
    />
  );
};

export default SubmitScreen;
