import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Grow,
  Fade,
} from "@mui/material";
import React from "react";
import { Star } from "@mui/icons-material";
const ReviewCardInvert = ({ review, index, name, date, src, comment }) => {
  const stars = new Array(review);
  for (let index = 0; index < review; index++) {
    stars[index] = index;
  }
  console.log(stars);
  return (
    <Grid container spacing={4} alignContent="center">
      <Grow in={true} {...{ timeout: 1000 * index }} style={styles.grow}>
        <Grid item xs={12} sm={8} md={8}>
          <Card sx={styles.card}>
            <Fade in={true}>
              <CardMedia image={src} sx={styles.image} />
            </Fade>
            <CardContent sx={styles.cardContent}>
              <div style={styles.starContanier}>
                {stars?.map((item, index) => {
                  return <Star key={index} sx={styles.star} />;
                })}
              </div>

              <Typography variant="h5" sx={styles.nameText}>
                {name?.toUpperCase()}
              </Typography>
              <Typography variant="body2" sx={styles.dateText}>
                {date}
              </Typography>
              <Typography variant="body1" sx={styles.text}>
                {comment}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grow>
    </Grid>
  );
};
const styles = {
  text: {
    color: "#828282",
    marginTop: 2,
    fontWeight: 500,
    flexGrow: 1,
  },
  nameText: { color: "#313131", marginTop: 2, fontWeight: 500 },
  dateText: { color: "#5A5B5D", marginTop: 1, fontWeight: 400 },
  image: { paddingRight: "30.25%", paddingBottom: "35%" },
  cardContent: { display: "flex", flexDirection: "column", flex: "auto" },
  starContanier: { display: "flex", flexDirection: "row", marginTop: "20%" },
  card: {
    flexDirection: "row",
    display: "flex",
    padding: 2,
    alignItem: "center",
    justifyContent: "center",
    marginBottom: 4,
    boxShadow: 7,
    marginTop: 3,
  },
  star: {
    color: "#E9E900",
  },
  grow: {
    flexGrow: 1,
    maxWidth: "100%",
  },
};
export default ReviewCardInvert;
