import React, { useState, useEffect } from "react";
import { CssBaseline, Container } from "@mui/material";
import { ReviewCard, ReviewCardInvert, SubmitForm } from "../components";
import axios from "axios";

const Reviews = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [reviews, setReviews] = useState([]);
  const [img, setImg] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    if (!(name, value, comment, email)) return alert("Please fill all fields");
    if (!img) return alert("Please select the product you buy");
    const temp = [...reviews];

    axios
      .post("https://review.rail19.com:5000/api/reviews", {
        name,
        email,
        comment: comment,
        img: img,
        review: value,
      })
      .then((res) =>
        alert(
          "Thank you for your review.it's under examination once admin approved you will be able too see in reviews page"
        )
      )
      .catch((err) => console.log(err));

    setReviews(temp);
    setName("");
    setComment("");
    setEmail("");
    setValue(1);
    setImg("");
    setOpen(false);
  };
  const handleImageClick = (img) => {
    console.log(img);
    setImg(img);
  };
  useEffect(() => {
    axios
      .get("https://review.rail19.com:5000/api/reviews")
      .then((res) => {
        setReviews(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <CssBaseline />
      <main>
        <div>
          <Container maxWidth="lg">
            {reviews?.map((item, index) => {
              return index % 2 ? (
                <ReviewCardInvert
                  review={item?.review}
                  index={index}
                  key={index}
                  name={item?.name}
                  comment={item?.comment}
                  date={item?.createdAt.slice(0, 10)}
                  src={item?.img}
                />
              ) : (
                <ReviewCard
                  review={item?.review}
                  index={index}
                  key={index}
                  name={item?.name}
                  comment={item?.comment}
                  date={item?.createdAt.slice(0, 10)}
                  src={item?.img}
                />
              );
            })}
          </Container>
        </div>
      </main>
    </>
  );
};

export default Reviews;
